import { Injectable } from '@angular/core';
import { UrlEnum } from 'src/app/shared/enum/url.enum';
import { UtilitiesService } from '../../utilities/utilities.service';
import { HttpRequestService } from '../../http-request/http-request.service';
import { map, Observable } from 'rxjs';
import { CorporateDepartmentModel } from 'src/app/shared/model/corporate/corporate-department.model';
import { CorporateEmployeeModel } from 'src/app/shared/model/corporate/corporate-employee.model';
import {
  CorporateProfileModel,
  CorporateProfileSignupModel,
} from 'src/app/shared/model/corporate/corporate-profile.model';
import { LocalStorageService } from '../../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CorporateProfileService {
  private corporateProfileUrl = `${UrlEnum.SHOHAY_CORPORATE}/company-profile`;
  private corporateDepartmentUrl = `${UrlEnum.SHOHAY_CORPORATE}/employee-department`;
  private corporateEmployeeUrl = `${UrlEnum.SHOHAY_CORPORATE}/employee`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public getCompanyDashboard(companyId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.corporateProfileUrl}/get-dashboard-summary/${companyId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getCompanyProfile(userId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.corporateProfileUrl}/get-by-user/${userId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createCorporateProfile(
    profileBody: CorporateProfileSignupModel
  ): Observable<any> {
    return this.httpRequestService
      .post(`${this.corporateProfileUrl}/create`, profileBody)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateCorporateProfile(
    companyId: string,
    profileBody: CorporateProfileModel
  ): Observable<any> {
    return this.httpRequestService
      .patch(`${this.corporateProfileUrl}/update/${companyId}`, profileBody)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getDepartments(companyId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.corporateDepartmentUrl}/get-by-company/${companyId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createDepartment(body: CorporateDepartmentModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.corporateDepartmentUrl}/create`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
  public createEmployee(body: CorporateEmployeeModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.corporateEmployeeUrl}/create`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getEmployee(userId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.corporateEmployeeUrl}/get-by-user/${userId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getEmployeeCampaignDashboard() {
    const sessionUser = this.localStorageService.getSessionUser();
    return this.httpRequestService
      .get(
        `${this.corporateEmployeeUrl}/get-campaign-dashboard-summary/${sessionUser?.id}`
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
